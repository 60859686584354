body {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

  --toolbar-background: #fafafa;
  --toolbar-border: #eaeaea;
  --link-color: #37a;
  --card-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0;
  overflow: hidden;
}

.code-field .MuiOutlinedInput-root {
  font-family: "monospace";
  font-size: 0.8rem;
  line-height: 1;
  border-radius: 0 !important;
}


.coder {
  font-family: monospace !important;
}

.card-body {
  margin: 12px 0;
  background-color: white;
}

.preview-stack {
  border: solid 1px rgba(0, 0, 0, 0.4);
  margin: 12px;
  border-radius: 4px;
  max-width: 348px;
  min-height: 230px;
}
.preview-head {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 4px;
  display: flex;
}

.no-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.underline {
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}

 .dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: tomato;
}

 .dot.green {
  background-color: lime;
}

 .dot.gold {
  background-color: gold;
}

a,
.link {
  color: var(--link-color); 
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.preview-body {
  padding: 4px;
}

.flex {
  display: flex;
}
.flex.center {
  align-items: center;
}
.flex.middle {
  justify-content: center;
}

.auto-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  padding: 24px;
}

.spin {
  animation: spin 0.4s infinite linear;
}

.progress-stack {
  background-color: aliceblue;
  border-radius: 4px;
}

.code-block-inner {
  max-width: 400px;
  width: 400px;
  overflow: hidden;
  padding: 12px;
}

.code-block {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,0.4);
  background-color: rgba(0,0,0,0.2);
}

.application-footer,
.application-header {
  height: 48px;
  padding: 0 24px;
  background-color: rgb(51, 6, 15);
  color: white;
}

.application-footer {
  height: 32px;
}

.application-body {
  padding: 0 48px;
  overflow: auto;
}

.bold {
  font-weight: 900 !important;
}

.gray {
  color: #222;
  background-color: rgba(90, 90, 90, 0.1);
}

.menu-item:hover {
  cursor: pointer;
  background-color: rgba(190, 190, 190, 0.1);
}


.white {
  color: white;
}

.logo-font {
  font-family: 'Syne Mono', monospace;
  font-size: 1.2rem;
}

.grid {
  width: 100%;
}

.grid th {
  text-align: left;
  font-size: .8rem;
  padding: 8px 24px;
  color:rgba(0, 0, 0, 0.4);
  border-bottom: solid 2px rgba(0, 0, 0, 0.2);
  background-color: var(--toolbar-background);
}

.grid td {
  padding: 4px 24px;
  margin: 0; 
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  font-size: .9rem;
}

.grid td.checked {
  padding: 4px;
  text-align: right;
}

.dialog-header ,
.panel-header {
  background-color: var(--toolbar-background);
  border-bottom: solid 1px var(--toolbar-border);
}

.dialog-header { 
  padding-bottom: 4px !important;
  padding-top: 8px !important; 
}


.flip {
  transition: transform 0.2s linear !important;
}

.flip.right {
  transform:rotate(270deg) !important;
}

.flip.up {
  transform:rotate(180deg) !important;
}

.input-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  color: white !important;
}

.input-label input::placeholder {
  text-overflow: ellipsis !important;
  color: lime !important;
} 

.MuiButton-root {
  text-transform: capitalize !important;
  border-radius: 0 !important;
}

.mono,
.mono .MuiOutlinedInput-root {
  font-family: Inconsolata, monospace !important;
}

fieldset {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

fieldset legend.button {
  padding: 0 8px;
  font-size: .9rem;
  cursor: pointer;
  box-shadow: var(--card-shadow);
}

fieldset legend.button:active {
  padding: 0 8px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  box-shadow: none;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
.stringCode {
  color: green;
}
 
.numberCode {
  color: orange;
}
.booleanCode {
  color: blue;
  font-weight: 600;
}
.keyCode {
  color: firebrick;
}

.half {
  width: calc(50% - 56px);
}